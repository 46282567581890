<template>
  <div class="task-comments">
    <div class="task-comments-header">
      {{ $t('tasks.taskDetails.info.operationHistory.header') }}
    </div>
    <div
      v-for="(comment, i) in filteredComments"
      :key="`task_${task.id}_comment_${i}`"
      class="task-comment">
      <div class="task-comment-header">
        {{ comment.name }}
        {{ formatCommentTimestamp(comment.createdAt) }}
      </div>
      <div class="task-comment-content">
        {{ comment.comment }}
      </div>
    </div>
    <template v-if="comments.length > commentsFilterCount">
      <a @click="commentsFilterCount += 5">
        {{ $t('tasks.taskDetails.info.operationHistory.showMoreComments') }}
      </a>
      <span>•</span>
      <a @click="commentsFilterCount = comments.length">
        {{ $t('tasks.taskDetails.info.operationHistory.showAllComments', { count: comments.length - commentsFilterCount }) }}
      </a>
    </template>
    <div class="task-comments-create">
      <b-input
        v-model="commentInput"
        type="textarea"
        rows="2"
        @keyup.native.enter.ctrl.exact="addComment"/>
      <b-button
        type="is-dark"
        :disabled="!commentInput"
        @click="addComment">
        {{ $t('tasks.taskDetails.info.operationHistory.commentButtonTitle') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { formatDate, formatTimeFromDate } from '@js/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'task-comments',
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      commentsFilterCount: 5,
      commentInput: null
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading',
      'getErrorMessage'
    ]),
    createCommentError() {
      return this.getErrorMessage('task/createTaskComment')
    },
    comments() {
      if (this.task.comments) {
        return this.task.comments.concat().reverse()
      }
      return []
    },
    filteredComments() {
      return this.comments.slice(0, this.commentsFilterCount)
    }
  },
  watch: {
    task() {
      this.commentsFilterCount = 5
    }
  },
  created() {
    this.watchErrorNotification('createCommentError')
  },
  methods: {
    ...mapActions('task', [
      'createTaskCommentRequest'
    ]),
    formatCommentTimestamp(date) {
      date = new Date(date)
      // omit year if is this year
      const formattedDate = formatDate(date, { yearFirst: false, zeroPadded: false })
      const dateComponents = formattedDate.split('-')
      const year = parseInt(dateComponents[dateComponents.length - 1])
      if (year === new Date().getFullYear()) {
        dateComponents.pop()
      }
      const reformattedDate = dateComponents.join('/')

      const formattedTime = formatTimeFromDate(date)
      return `${formattedTime} - ${reformattedDate}`
    },
    addComment() {
      if (this.commentInput) {
        this.addApiListeners('task/createTaskComment', this.onCreateCommentComplete)
        this.createTaskCommentRequest({
          taskId: this.task.id,
          comment: this.commentInput
        })
      }
    },
    onCreateCommentComplete() {
      this.removeApiListeners('task/createTaskComment', this.onCreateCommentComplete)
      if (!this.createCommentError) {
        this.openToast({
          message: this.$t('tasks.taskDetails.info.operationHistory.createCommentSuccess'),
          type: 'is-success'
        })
        this.commentInput = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.task-comments {
  .task-comments-header {
    margin-bottom: $space-xs;
    font-weight: bold;
    @extend %body;
  }

  .task-comment {
    &:not(:last-child) {
      margin-bottom: $space-xs;
    }

    .task-comment-header {
      margin-bottom: $space-xxs;
      @extend %small;
    }

    .task-comment-content {
      padding: $space-xs;
      background-color: $grey-lighter;
    }
  }

  .task-comments-create {
    margin-top: $space-m;

    &::v-deep textarea {
      @extend %body;
    }

    .button {
      margin-top: $space-m;
      font-size: $font-size-h5;
    }
  }
}
</style>
